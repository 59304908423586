import * as React from "react"

const CookinGenieHeroSection = (props) => {
    return (
        <section className="page-header-section case-study-hero-section cookingenie-hero-section p-b-0">
            <div className="container">
                <div className="row">
                    <div className="col-lg-13">
                        <p className="small section-description">{props.preTitle}</p>
                        <h1 className="h1 section-title large">{props.mainTitle}</h1>  
                    </div>
                </div>
                <div className="image-block">
                    <img
                        src={props.image1x?.sourceUrl}
                        srcSet={props.image2x?.sourceUrl + " 2x, " + props.image1x?.sourceUrl + " 1x"}
                        width={props.image1x?.width}
                        alt={props.image1x?.altText}
                    />
                </div>
                <div className="mobile-image-block">
                    <img
                        src={props.mobileImage?.sourceUrl}
                        width={props.mobileImage?.width}
                        alt={props.mobileImage?.altText}
                    />
                </div>
            </div>
        </section> 
    )
}

export default CookinGenieHeroSection
